// If you don't want to use TypeScript you can delete this file!
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/home/index.css"
import "../css/home/responsive.css"
import { MetaData } from "../utils/globals"
import { Helmet } from "react-helmet"
import Politics from "../components/privacyPolitics";

const Index: React.FC<{}> = ({}) => (
  <>
    {/* SEO Start */}
    {/* <Seo
      title={MetaData.HOME.META.title}
      description={MetaData.HOME.META.description}
      keywords={MetaData.HOME.META.keywords}
      author={MetaData.HOME.META.author}
      lang={MetaData.HOME.META.lang}
      meta={MetaData.HOME.META.meta}
      image={MetaData.HOME.META.image}
      portada={MetaData.HOME.META.portada}
    /> */}
    {/* SEO End */}
    {/* Layout Start */}
    <Layout>
        <Politics/>
    </Layout>
    {/* Layout End */}

    <Helmet>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/5117462.js"
      ></script>
    </Helmet>
  </>
)

export default Index
