import React, { useState } from 'react';
import './styles.scss';

const Terms = () => {
	const termsText = (
		<p className="terms-text">
			<strong>Aspectos Generales</strong>
			<br />
			Estori (como se conocerá en adelante), crea la siguiente política de privacidad y tratamiento de los datos que sean recolectados
			provenientes de personas, tanto naturales como jurídicas durante y después de la prestación del servicio de información prestada, con
			fines estrictamente relacionados con aspectos comerciales, garantizando el derecho de todos los intervinientes a conocer, actualizar y
			rectificar las informaciones que sobre ellos se hubiere recogido.
			<br />
			<br /> <strong>Recolección de la información</strong> <br />
			Responsable del tratamiento de los datos:
			<br />
			Nombre: Pluriza S.A.S
			<br />
			Teléfono: +57 3002061607
			<br />
			Correo electrónico: alastrat@pluriza.com
			<br />
			Encargado del tratamiento de datos:
			<br />
			Pluriza S.A.S, se encargará de la recolección, almacenamiento, manejo, trasmisión, rectificación, actualización y protección de los
			datos provenientes de los usuarios de la plataforma, así como de terceros.
			<br />
			<br /> <strong> Política de tratamiento de datos</strong> <br />
			Pluriza S.A.S. recibe y almacena información sobre usted, la cual incluye:
			<br />
			La información que usted nos entrega: Compilamos información que usted nos entrega en diferentes momentos, como lo son:
			<br />
			• Al momento de interactuar a través de la aplicación móvil WhatsApp o de la plataforma web www.estori.co, se solicitarán los
			siguientes datos para poder prestar el servicio ofrecido por la plataforma:
			<br />
			Para el usuario agente: correo y clave de acceso.
			<br />
			Para el usuario comprador: número de cédula, descripción clara de pedidos, número de celular, número de cuenta nequi.
			<br />
			Para el usuario asociado: Horario, lista de precios, números de contacto, sedes.
			<br />
			• Al momento de que el usuario comprador realiza los pedidos, al momento de que el usuario agente apoya en la gestión de la
			información de los pedidos y al momento de que el usuario asociado recibe y gestiona los pedidos y la logística final.
			<br />
			• Cuando existe interacción con nuestro servicio al cliente.
			<br />
			• Cuando usted presenta solicitudes, quejas o reclamos ante Estori y le solicitamos datos para poder solucionar su inconformidad.
			<br />
			• Cuando usted participa en encuestas o promociones de marketing.
			<br />
			• Cuando usted nos proporciona reseñas, calificaciones o preferencias.
			<br />
			• Cuando la plataforma necesite información adicional para uso del servicio y la solicite directamente.
			<br />
			• Información compilada automáticamente: Recopilamos información relacionada con usted, el uso de nuestro servicio, sus interacciones
			con nosotros y la publicidad, igualmente de aquella información relacionada con el dispositivo que usted utiliza para acceder a
			nuestro servicio. Algunos ejemplos de lo que incluye esta información son:
			<br />
			• ID de dispositivos u otros identificadores únicos, características de software y dispositivo, información de conexión, estadísticas
			sobre visualización de las páginas, URL de referencias, direcciones IP, Su ubicación para prestarle un mejor servicio.
			<br />
			• La información compilada a través del uso de cookies, señalizaciones web y otras tecnologías, incluidos datos de publicidad
			(información sobre la disponibilidad y entrega de anuncios, el URL del sitio, así como la fecha y horas).
			<br />
			• Información de otras fuentes: También complementamos información anterior con información de otras fuentes, como proveedores de
			datos obtenidos tanto online como sin offline. La información puede incluir datos demográficos, datos basados en los intereses y
			conductas de navegación por internet.
			<br />
			<br /> <strong> ¿Cómo usamos tu información?</strong> <br />
			Usamos la información compilada para procesar su registro, solicitudes, analizar, administrar, mejorar y personalizar nuestros
			servicios e iniciativas de marketing, para comunicarnos con usted y para prestarle un servicio cada vez mejor, por ejemplo, la
			utilizamos para lo siguiente:
			<br />
			• Determinar la ubicación geográfica donde se encuentra, con el fin de acceder y/o prestar los servicios de información que estén
			ubicados en su posición geográfica, ofrecerle contenidos, brindarle recomendaciones, determinar su proveedor de servicio de internet,
			ofrecerle información comercial, responder con mayor eficiencia a las consultas y solicitudes, y demás que sean necesarias para
			prestarle un mejor servicio de información.
			<br />
			• Compartirla con los usuarios agentes y asociados para la gestión de la información integral de los servicios que le prestan a los
			usuarios compradores.
			<br />
			• Detectar, prevenir e investigar actividades prohibidas, como el fraude, hacer cumplir nuestros términos.
			<br />
			• Analizar y comprender a nuestros usuarios, mejorar nuestro servicio y optimizar la selección de los contenidos, los algoritmos de
			recomendación y la entrega.
			<br />
			• Comunicarnos con usted de acuerdo a nuestro servicio (notificaciones, email, mensajes de texto y mensajería online), con el fin de
			enviarle noticias sobre la plataforma Estori, nuevas funciones y también ofertas que mejoren su experiencia. También para solucionar
			temas operativos como problemas técnicos.
			<br />
			Transferencia de información:Transferimos su información a terceros con el fin de mejorar su experiencia como usuario y que usted
			acceda a promociones y beneficios efectivos relacionados con el servicio.
			<br />
			<br /> <strong> Tratamiento específico</strong> <br />
			Los datos que sean aportados por los usuarios de la plataforma y demás terceros, serán sujetos a recolección, almacenamiento, uso,
			transferencia, transmisión, supresión o suspensión, modificación, actualización, observando en todo lugar, los principios de Seguridad
			y confidencialidad, así como también, el deber de informar a todos aquellos que hayan aportado datos, sobre cualquier alteración que
			involucre dicha información. En vista de lo anterior, solo las personas que demuestren estar autorizadas por parte de los titulares de
			la información ya sea para su retiro, modificación o alteración de cualquier tipo, se les permitirá realizar dichas actividades,
			aquellos que no se les negara esta posibilidad, éstas, deberán expresarse en forma escrita por parte del titular de la información
			describiendo claramente su deseo frente al manejo de sus datos.
			<br />
			Los datos serán utilizados con las siguientes finalidades entre otras:
			<br />
			• Recolectar en la base de datos de Estori información necesaria para el ingreso a la plataforma de los usuarios (asociados, agentes y
			comprador).
			<br />
			• Almacenar dicha información en las bases de datos de Estori.
			<br />
			• Estudiar dicha información con fines de mejorar los servicios de información que disponga Estori.
			<br />
			• Suspender, sustituir, eliminar y modificar los datos inscritos contando con el consentimiento informado y previo del titular de los
			mismos en concordancia con los términos y condiciones.
			<br />
			• Enviar información comercial a los usuarios de Estori.
			<br />
			• Manejo responsable de los datos que ingresen en la base de datos de Estori.
			<br />
			• Analizar el comportamiento de los usuarios con el propósito de mejorar aspectos estructurales de Estori.
			<br />
			• Transferir datos entre los usuarios de la plataforma (usuarios asociados, agentes y compradores) con el fin de que los servicios
			concurrentes entre éstos se presten óptimamente.
			<br />
			• Prestar los servicios relacionados con la naturaleza de Estori.
			<br />
			<br /> <strong> Quejas, reclamos y solicitudes</strong> <br />
			Estori consciente de su compromiso con los datos que son aportados por todos los usuarios serán atendidos, escribiendo al correo
			electrónico: Estori@pluriza.com
			<br />
			<br /> <strong> Vigencia</strong> <br />
			La presente política de datos comenzará a regir a partir de su publicación en la plataforma de Estori y podrá ser modificada en
			cualquier momento por la plataforma.
			<br />
			<br /> <strong> Pasarela de pagos</strong> <br />
			Pluriza S.A.S. utiliza la pasarela de pago de CrediBanco. Para consultar su política de protección y tratamiento de datos personales,
			por favor diríjase al siguiente link. <br />
			<a href="https://www.credibanco.com/sites/default/files/politica_de_proteccion_y_tratamiento_de_datos_personales.pdf" target="_blank">
				Credibanco Tratamiento de Datos Personales
			</a>
			<br />
		</p>
	);
	return (
		<div className="terms-container">
			<div className="terms-title">POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES</div>

			<div className="terms-text-container">{termsText}</div>
		</div>
	);
};

export default Terms;
